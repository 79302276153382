<template>
  <ul>
    <li>Antal anmälda: {{ numberOfSignedupMembers }}</li>
    <li>Antal betalda medlemsavgifter: {{ numberOfPaidMembershipFees }}</li>
    <li>Antal betalda träningsavgifter: {{ numberOfPaidWorkoutFees }}</li>
  </ul>
</template>

<script>
  export default {
    props: {
      members: Array
    },
    computed: {
      numberOfSignedupMembers() {
        return this.members.filter(m => m.signups && m.signups.includes('ht-2024')).length
      },
      numberOfPaidMembershipFees() {
        return this.members.filter(m => this.membershipPaid(m.fees)).length
      },
      numberOfPaidWorkoutFees() {
        return this.members.filter(m => this.workoutPaid(m.fees)).length
      }
    },
    methods: {
      membershipPaid(fees) {
        return (fees && fees.filter(f => f.type === 'membership' && f.year === '2024').length > 0);
      },
      workoutPaid(fees) {
        return (fees && fees.filter(f => f.period === 'ht-2024' && f.type.startsWith('workout-')).length > 0);
      }
    }
  }

</script>
