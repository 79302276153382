<template>
  <div>
    <b-form-checkbox
       v-model="showOnlySignups"
    >Visa enbart anmälda</b-form-checkbox>
    <b-table hover responsive="xl" :items="memberList" :fields="fields" sortBy="rank">
      <template #cell(rank)="data">
        <RankImage v-if="data.item.rank" :rank="data.item.rank" />
      </template>
    </b-table>
  </div>
</template>

<script>
  import RankImage from '@/components/RankImage.vue'
  import { yearsBetweenDates } from '@/utils/date.js'

  export default {
    components: { RankImage },
    props: {
      members: Array,
      memberships: Array
    },
    computed: {
      showAllMembers() {
        return !this.showOnlySignups
      },
      memberList() {
        return this.members.map(m => {
          return {
            rank: m.rank,
            name: `${m.firstName} ${m.lastName}`,
            age: `${this.ageAt(new Date(), m)}`,
            functions: m.functions === undefined ? '' : m.functions.join(', '),
            signedup: m.signups && m.signups.includes('ht-2024') ? '✓' : '',
            membership: this.membershipPaid(m.fees) ? '✓' : '',
            workout: this.workoutPaid(m.fees) ? '✓' : '',
            membershipNum: this.membershipNumber(m.id, '2024')
        }})
        .filter(m => this.showAllMembers || m.signedup)
      }
    },
    methods: {
      membershipPaid(fees) {
        return (fees && fees.filter(f => f.type === 'membership' && f.year === '2024').length > 0);
      },
      workoutPaid(fees) {
        return (fees && fees.filter(f => f.period === 'ht-2024' && f.type.startsWith('workout-')).length > 0);
      },
      birthdate(member) {
        const birthdate = member.birthdate ? member.birthdate : member.personalIdNumber.slice(0, 8)
        return new Date(birthdate.slice(0, 4) + "-" + birthdate.slice(4, 6) + "-" + birthdate.slice(6, 8))
      },
      ageAt(targetDate, member) {
        return yearsBetweenDates(this.birthdate(member), targetDate)
      },
      membershipNumber(memberId, year){
        return this.memberships.find(fee => fee.memberId === memberId && fee.year === year)?.number
      }
    },
    data() {
      return {
        showOnlySignups: true,
        fields: [
          {key: 'rank', sortable: true, label: 'Grad'},
          {key: 'name', sortable: true, label: 'Namn'},
          {key: 'age', sortable: true, label: 'Ålder'},
          {key: 'functions', sortable: true, label: 'Funktioner'},
          {key: 'signedup', sortable: true, label: 'Anmäld'},
          {key: 'membership', sortable: true, label: 'Medlem'},
          {key: 'workout', sortable: true, label: 'Träning'},
          {key: 'membershipNum', sortable: true, label: 'Nr'}
        ]
      }
    }
  }

</script>
